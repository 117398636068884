<template>
  <v-dialog v-model="showPositionMenuDialog" persistent max-width="1000px">
    <v-card class="pa-3" :loading="dialogLoading" :disabled="dialogLoading">
      <v-card-title>
        <v-row>
          <v-col cols="12" class="text-center">{{ getJobRoleName }}</v-col>
          <v-icon class="close-btn" @click="closeDialog">mdi-close</v-icon>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-card variant="tonal">
              <v-row class="pa-2">
                <v-col cols="11">
                  <v-row>
                    <v-col cols="12">Created At: {{ formatDate(selectedPosition?.CreatedAt) }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">Job Title: {{ getJobTitleNames }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">Skills: {{ getSkillNames }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">Number of Seats: {{ getNumberOfSeats }}</v-col>
                    <v-col cols="4">Start Date: {{ getStartDate }}</v-col>
                    <v-col cols="4">
                      Seats Unfilled:
                      {{ getUnfilledSeats() }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1" class="d-flex justify-center align-center">
                  <v-icon @click="showUpdatePosition = true">mdi-pen</v-icon>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-card variant="tonal">
              <v-row class="pa-2">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" class="text-center">Actions</v-col>
                  </v-row>
                  <div class="menu-action-btns">
                    <v-btn class="menu-btn" prepend-icon="mdi-content-copy">Clone</v-btn>
                    <v-btn class="menu-btn" prepend-icon="mdi-archive">Archive</v-btn>
                    <v-btn :disabled="!canAssignTeammember()" class="menu-btn" prepend-icon="mdi-magnify" @click="showSearchTeammember = true">Assign Teammember</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card variant="tonal">
              <v-row class="pa-2">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" class="text-center">Status</v-col>
                  </v-row>
                  <div class="menu-action-btns">
                    <v-btn class="menu-btn">174 Impressions</v-btn>
                    <v-btn class="menu-btn">57 Assessments</v-btn>
                    <v-btn class="menu-btn">23 Interviews</v-btn>
                    <v-btn class="menu-btn">5 Shortlisted</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showUpdatePosition" persistent max-width="600px" @keydown.esc="showUpdatePosition = false">
    <AddOrEditPositionDetails
      :isNew="false"
      :addLoading="addLoading"
      @cancel="showUpdatePosition = false"
      @success="UpdateProjectPosition($event)"
      :details="details"
      :position="selectedPosition"
      :skills="skills"
      v-if="showUpdatePosition" />
  </v-dialog>
  <ProjectSearchWizard
    @AssignTmToPosition="AssignTmToPosition"
    @UpdatePositionDetail="UpdatePositionDetail"
    :skills="skills"
    :details="details"
    @OnClose="showSearchTeammember = false"
    :project="project"
    :position="selectedPosition"
    :projectLeads="projectLeads"
    :portfolioManagers="portfolioManagers"
    :publicHolidayZones="publicHolidayZones"
    :projects="projectList"
    :officeLocations="officeLocations"
    @saveLocation="saveOfficeLocation"
    :dialogLoading="dialogLoading"
    :lineManagers="lineManagers"
    :officeSpaces="officeSpaces"
    v-if="showSearchTeammember" />
</template>
<script lang="ts">
  import { defineComponent } from "vue"
  import { ClientPositionApi, PositionDetailResponseModel, ProjectPositionResponseModel, TeammemberWithVmDetails } from "shared-components/src/services/openApi/api"
  import moment from "moment"
  import PositionService from "@/services/PositionService"
  import AddOrEditPositionDetails from "../ProjectPosition/AddOrEditPositionDetails.vue"
  import ProjectSearchWizard from "../AddTeammember/ProjectSearchWizard.vue"
  import Commitment from "shared-components/src/models/Commitment"
  import store from "@/store"
  import Utils from "shared-components/src/utils/Utils"

  export default defineComponent({
    components: {
      AddOrEditPositionDetails,
      ProjectSearchWizard,
    },
    props: [
      "skills",
      "details",
      "projectLeads",
      "publicHolidayZones",
      "projectList",
      "officeLocations",
      "positionId",
      "project",
      "lineManagers",
      "officeSpaces",
      "portfolioManagers",
    ],
    data() {
      return {
        dialogLoading: false,
        showSearchTeammember: false,
        addLoading: false,
        showUpdatePosition: false,
        showPositionMenuDialog: true,
        selectedPosition: null as PositionDetailResponseModel | null,
      }
    },
    async created() {
      if (this.getPositionId()) {
        this.dialogLoading = true
        this.selectedPosition = (await new ClientPositionApi().clientGetPositionById(this.getPositionId())).data
        this.dialogLoading = false
      }
    },
    methods: {
      canAssignTeammember() {
        return this.getUnfilledSeats() > 0
      },
      getUnfilledSeats() {
        return this.selectedPosition?.SeatUnfilled ?? 0
      },
      async saveOfficeLocation(event: any) {
        this.officeLocations.push(event)
      },
      async AssignTmToPosition(tm: TeammemberWithVmDetails) {
        this.dialogLoading = true
        if (this.selectedPosition?.SeatUnfilled) {
          this.selectedPosition.SeatUnfilled -= 1
        }

        this.project.Teammembers.push(tm)
        this.addLoading = false
        this.showSearchTeammember = false
        this.dialogLoading = false
        this.closeDialog()
      },
      getPositionId() {
        return this.positionId ?? this.$route.query.positionId
      },
      async UpdatePositionDetail(jobDescription: any) {
        if (this.project && this.project.Positions) {
          this.dialogLoading = true

          let positionIndex = this.project.Positions.findIndex((item: any) => item.Id == this.getPositionId())
          this.project.Positions[positionIndex].JobDescription = jobDescription
          this.addLoading = false
          this.showSearchTeammember = false
          this.dialogLoading = false
        }
      },
      async UpdateProjectPosition(event: any) {
        this.addLoading = true
        if (this.project && this.project.Positions) {
          event.ProjectId = this.project.Id
          event.Id = this.getPositionId()
          try {
            const result = await PositionService.SavePosition(event)
            let positionIndex = this.project?.Positions?.findIndex((item: any) => item.Id == this.getPositionId())
            this.selectedPosition = result
            this.project.Positions[positionIndex] = {
              CountryId: this.selectedPosition.CountryId,
              Id: this.selectedPosition.Id,
              JobRoleName: this.getJobRoleName,
              JobTitles: this.selectedPosition.JobTitles?.map((item) => item.Name).join(", "),
              NoOfPositions: this.selectedPosition.NoOfPositions,
              ProjectId: this.selectedPosition.ProjectId,
              Skills: this.selectedPosition.Skills?.map((item) => item.Text).join(", "),
              StartDate: this.selectedPosition.StartDate,
            } as ProjectPositionResponseModel
            this.project.Teammembers.forEach((tm: TeammemberWithVmDetails) => {
              if (tm.CommitmentPositionId == this.selectedPosition?.Id) {
                tm.JobRole = this.getJobRoleName
                tm.JobTitle = this.selectedPosition?.JobTitles?.map((item) => item.Name).join(", ")
              }
            })
            this.addLoading = false
            this.showUpdatePosition = false
            store.dispatch("showSuccessMessage", "Position Updated successfully.")
          } finally {
          }
        }
      },
      closeDialog() {
        this.$emit("OnClose")
      },
      formatDate(date: any) {
        date = new Date(date)
        if (date) {
          return Utils.toVsDateFormat(date)
        }
      },
    },
    computed: {
      getJobRoleName() {
        return this.details.find((item: any) => item.id == this.selectedPosition?.JobRole)?.Name ?? ""
      },
      getJobTitleNames() {
        if (this.selectedPosition?.JobTitles && this.selectedPosition?.JobTitles.length > 0) {
          const names = this.details.filter((item: any) => this.selectedPosition?.JobTitles?.map((item) => item.id).includes(item.id)).map((item: any) => item.Name)
          return names.length > 0 ? names.join(", ") : "-"
        }
        return "-"
      },
      getSkillNames() {
        if (this.selectedPosition?.Skills && this.selectedPosition?.Skills.length > 0) {
          const names = this.skills.filter((item: any) => this.selectedPosition?.Skills?.map((item) => item.id).includes(item.id)).map((item: any) => item.Text)
          return names.length > 0 ? names.join(", ") : "-"
        }
        return "-"
      },
      getStartDate() {
        return this.selectedPosition?.StartDate ? moment(this.selectedPosition?.StartDate).format("YYYY-MM-DD") : "ASAP"
      },
      getNumberOfSeats() {
        return this.selectedPosition?.NoOfPositions
      },
      getSeatsUnfilled() {
        if (this.selectedPosition?.NoOfPositions && this.selectedPosition?.Commitments) {
          return this.selectedPosition?.NoOfPositions - this.selectedPosition?.Commitments?.length
        }
        return "-"
      },
    },
  })
</script>
<style lang="scss" scoped>
  .menu-action-btns {
    margin: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .menu-btn {
      font-size: 12px;
      flex: 45%;
    }
  }
  .close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9999;
    color: white;
  }
</style>
