import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pl-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_timeline_item = _resolveComponent("v-timeline-item")!
  const _component_v_timeline = _resolveComponent("v-timeline")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_v_timeline, {
    density: "compact",
    side: "end",
    "truncate-line": "both"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_timeline_item, {
        "dot-color": _ctx.getTimelineColor(_ctx.firstRelatedCommitment),
        size: "large",
        "fill-dot": ""
      }, _createSlots({
        default: _withCtx(() => [
          _createVNode(_component_v_card, { class: "timeline-item pa-2" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { "no-gutters": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "text-center"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.commitment?.Name), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Project Name: " + _toDisplayString(_ctx.truncate(_ctx.commitment?.ProjectName)), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Start Date: " + _toDisplayString(_ctx.formatDate(_ctx.firstRelatedCommitment?.StartDate)), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _createTextVNode("End Date: " + _toDisplayString(_ctx.formatDate(_ctx.firstRelatedCommitment?.EndDate)), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Project Lead: " + _toDisplayString(_ctx.firstRelatedCommitment?.ProjectLeadName), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Portfolio Manager: " + _toDisplayString(_ctx.firstRelatedCommitment?.PortfolioManagerName), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Holiday Zone: " + _toDisplayString(_ctx.firstRelatedCommitment?.PublicHolidayZoneName), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Location Type: " + _toDisplayString(_ctx.commitment ? _ctx.firstRelatedCommitment?.LocationType : ""), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 2
      }, [
        (_ctx.commitment.RelatedActions?.length == 0)
          ? {
              name: "icon",
              fn: _withCtx(() => [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode("mdi-star")
                  ])),
                  _: 1
                })
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["dot-color"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.commitment.RelatedActions, (item, index) => {
        return (_openBlock(), _createBlock(_component_v_timeline_item, {
          key: index,
          "dot-color": _ctx.getTimelineColor(item.ToCommitmentDetail)
        }, _createSlots({
          default: _withCtx(() => [
            _createVNode(_component_v_card, { class: "timeline-item pa-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { "no-gutters": "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createTextVNode("Requested at: " + _toDisplayString(_ctx.formatDate(item.CreatedAt)), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_col, { cols: "6" }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Start Date: " + _toDisplayString(_ctx.formatDate(item.ToCommitmentDetail?.StartDate)), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_col, { cols: "6" }, {
                      default: _withCtx(() => [
                        _createTextVNode("End Date: " + _toDisplayString(_ctx.formatDate(item.ToCommitmentDetail?.EndDate)), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _cache[2] || (_cache[2] = _createTextVNode(" Applied Actions: ")),
                        _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                        _createElementVNode("div", _hoisted_1, [
                          _createTextVNode(_toDisplayString(item.DisplayName) + " ", 1),
                          _withDirectives(_createVNode(_component_v_icon, {
                            size: "small",
                            icon: "mdi-information"
                          }, null, 512), [
                            [_directive_tooltip, _ctx.getActionDetail(item), "top"]
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, [
          (index == _ctx.commitment.RelatedActions?.length - 1)
            ? {
                name: "icon",
                fn: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("mdi-star")
                    ])),
                    _: 1
                  })
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["dot-color"]))
      }), 128))
    ]),
    _: 1
  }))
}