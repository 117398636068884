import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "tm-name" }
const _hoisted_2 = {
  key: 0,
  class: "menu-action-btns"
}
const _hoisted_3 = {
  key: 1,
  class: "no-action-container"
}
const _hoisted_4 = { class: "note" }
const _hoisted_5 = {
  key: 2,
  class: "no-action-container"
}
const _hoisted_6 = { class: "menu-action-btns" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "help-section" }
const _hoisted_9 = { class: "help-item" }
const _hoisted_10 = { class: "timeline-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_cols = _resolveComponent("v-cols")!
  const _component_CommitmentMenuTimeline = _resolveComponent("CommitmentMenuTimeline")!
  const _component_ActivityWatchReport = _resolveComponent("ActivityWatchReport")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_SecurityReport = _resolveComponent("SecurityReport")!
  const _component_VdSupport = _resolveComponent("VdSupport")!
  const _component_CommitmentActionModal = _resolveComponent("CommitmentActionModal")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.showCommitmentMenuDialog,
    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.showCommitmentMenuDialog) = $event)),
    persistent: "",
    "max-width": "1000px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        class: "pa-3",
        loading: _ctx.dialogLoading,
        disabled: _ctx.dialogLoading
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  (_ctx.selectedCommitment?.TeammemberId)
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 0,
                        cols: "12",
                        class: "text-center"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Avatar, {
                            Name: _ctx.selectedCommitment?.TeammemberName,
                            Photo: _ctx.selectedCommitment?.PhotoUrl,
                            class: "tm-avatar"
                          }, null, 8, ["Name", "Photo"]),
                          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.selectedCommitment?.TeammemberName), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_icon, {
                    class: "close-btn",
                    onClick: _ctx.closeDialog
                  }, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createTextVNode("mdi-close")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_cols, {
                            cols: "12",
                            class: "w-100"
                          }, {
                            default: _withCtx(() => [
                              (_ctx.dialogLoading)
                                ? (_openBlock(), _createBlock(_component_v_skeleton_loader, {
                                    key: 0,
                                    class: "project-skeleton",
                                    type: "button@2, button@2, button@2"
                                  }))
                                : _createCommentVNode("", true),
                              (!_ctx.dialogLoading)
                                ? (_openBlock(), _createBlock(_component_v_card, {
                                    key: 1,
                                    variant: "tonal",
                                    class: "b-r-5 action-boxs"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_row, { class: "pa-2" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_col, { cols: "12" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_row, null, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_col, {
                                                    cols: "12",
                                                    class: "text-center"
                                                  }, {
                                                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                                                      _createTextVNode("Actions")
                                                    ])),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              }),
                                              (_ctx.selectedCommitment && _ctx.selectedCommitment.AllowedActions && _ctx.selectedCommitment.AllowedActions?.length > 0)
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                                    (_ctx.checkActionPermission(_ctx.actionLists.CustomerExtendContract))
                                                      ? (_openBlock(), _createBlock(_component_v_btn, {
                                                          key: 0,
                                                          class: "menu-btn",
                                                          "prepend-icon": "",
                                                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showExtendContractAction()))
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(_ctx.getAllowedActionName(_ctx.actionLists.CustomerExtendContract)), 1)
                                                          ]),
                                                          _: 1
                                                        }))
                                                      : _createCommentVNode("", true),
                                                    (_ctx.checkActionPermission(_ctx.actionLists.CustomerTerminateContract))
                                                      ? (_openBlock(), _createBlock(_component_v_btn, {
                                                          key: 1,
                                                          class: "menu-btn",
                                                          "prepend-icon": "",
                                                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showTerminateContractAction()))
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(_ctx.getAllowedActionName(_ctx.actionLists.CustomerTerminateContract)), 1)
                                                          ]),
                                                          _: 1
                                                        }))
                                                      : _createCommentVNode("", true),
                                                    (_ctx.checkActionPermission(_ctx.actionLists.CustomerAddVirtualDesktop))
                                                      ? (_openBlock(), _createBlock(_component_v_btn, {
                                                          key: 2,
                                                          class: "menu-btn",
                                                          "prepend-icon": "",
                                                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showAddVirtualDeskopAction()))
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(_ctx.getAllowedActionName(_ctx.actionLists.CustomerAddVirtualDesktop)), 1)
                                                          ]),
                                                          _: 1
                                                        }))
                                                      : _createCommentVNode("", true),
                                                    (_ctx.checkActionPermission(_ctx.actionLists.CustomerVdSupport))
                                                      ? (_openBlock(), _createBlock(_component_v_btn, {
                                                          key: 3,
                                                          class: "menu-btn",
                                                          "prepend-icon": "",
                                                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openVdSupport()))
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(_ctx.getAllowedActionName(_ctx.actionLists.CustomerVdSupport)), 1)
                                                          ]),
                                                          _: 1
                                                        }))
                                                      : _createCommentVNode("", true),
                                                    (_ctx.checkActionPermission(_ctx.actionLists.CustomerModifyVirtualDesktop))
                                                      ? (_openBlock(), _createBlock(_component_v_btn, {
                                                          key: 4,
                                                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showModifyVirtualDeskopAction())),
                                                          class: "menu-btn"
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(_ctx.getAllowedActionName(_ctx.actionLists.CustomerModifyVirtualDesktop)), 1)
                                                          ]),
                                                          _: 1
                                                        }))
                                                      : _createCommentVNode("", true),
                                                    (_ctx.checkActionPermission(_ctx.actionLists.CustomerTerminateVirtualDesktop))
                                                      ? (_openBlock(), _createBlock(_component_v_btn, {
                                                          key: 5,
                                                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showTerminateVirtualDeskopAction())),
                                                          class: "menu-btn"
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(_ctx.getAllowedActionName(_ctx.actionLists.CustomerTerminateVirtualDesktop)), 1)
                                                          ]),
                                                          _: 1
                                                        }))
                                                      : _createCommentVNode("", true)
                                                  ]))
                                                : (
                          _ctx.selectedCommitment && (!_ctx.selectedCommitment.AllowedActions || _ctx.selectedCommitment.AllowedActions?.length == 0) && _ctx.selectedCommitment.CommitmentActions
                        )
                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                                      _createElementVNode("span", _hoisted_4, " You have already submitted a request to make the following changes to this Commitment for " + _toDisplayString(_ctx.formatDate(_ctx.selectedCommitment?.CommitmentActions?.EffectiveDate)) + ", if you wish to Cancel/Update them, please contact an Administrator. ", 1),
                                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedCommitment?.CommitmentActions?.ActionItems, (action, index) => {
                                                        return (_openBlock(), _createElementBlock("div", { key: index }, [
                                                          _createTextVNode(_toDisplayString(action.DisplayName) + " ", 1),
                                                          _withDirectives(_createVNode(_component_v_icon, {
                                                            size: "small",
                                                            icon: "mdi-information"
                                                          }, null, 512), [
                                                            [_directive_tooltip, _ctx.getActionDetail(action), "top"]
                                                          ])
                                                        ]))
                                                      }), 128))
                                                    ]))
                                                  : (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[14] || (_cache[14] = [
                                                      _createElementVNode("span", { class: "note" }, "There are no any action for this commitment", -1)
                                                    ])))
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_cols, {
                            cols: "12",
                            class: "mt-2 w-100"
                          }, {
                            default: _withCtx(() => [
                              (_ctx.dialogLoading)
                                ? (_openBlock(), _createBlock(_component_v_skeleton_loader, {
                                    key: 0,
                                    class: "project-skeleton",
                                    type: "button@2, button@2, button@2"
                                  }))
                                : _createCommentVNode("", true),
                              (!_ctx.dialogLoading)
                                ? (_openBlock(), _createBlock(_component_v_card, {
                                    key: 1,
                                    variant: "tonal",
                                    class: "b-r-5 action-boxs"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_row, { class: "pa-2" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_col, { cols: "12" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_row, null, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_col, {
                                                    cols: "12",
                                                    class: "text-center"
                                                  }, {
                                                    default: _withCtx(() => _cache[15] || (_cache[15] = [
                                                      _createTextVNode("Insights")
                                                    ])),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              }),
                                              _createElementVNode("div", _hoisted_6, [
                                                _createVNode(_component_v_btn, { class: "menu-btn" }, {
                                                  default: _withCtx(() => _cache[16] || (_cache[16] = [
                                                    _createTextVNode("Current Status")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_btn, { class: "menu-btn" }, {
                                                  default: _withCtx(() => _cache[17] || (_cache[17] = [
                                                    _createTextVNode("Timesheet Flag Yesterday")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_btn, { class: "menu-btn" }, {
                                                  default: _withCtx(() => _cache[18] || (_cache[18] = [
                                                    _createTextVNode("Timesheet Flag Last Week")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_btn, { class: "menu-btn" }, {
                                                  default: _withCtx(() => _cache[19] || (_cache[19] = [
                                                    _createTextVNode("Contract Ending Soon")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_btn, { class: "menu-btn" }, {
                                                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                                                    _createTextVNode("Add-On Upsell")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_btn, { class: "menu-btn" }, {
                                                  default: _withCtx(() => _cache[21] || (_cache[21] = [
                                                    _createTextVNode("Notification")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_btn, { class: "menu-btn" }, {
                                                  default: _withCtx(() => _cache[22] || (_cache[22] = [
                                                    _createTextVNode("Attention")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_btn, { class: "menu-btn" }, {
                                                  default: _withCtx(() => _cache[23] || (_cache[23] = [
                                                    _createTextVNode("Issues")
                                                  ])),
                                                  _: 1
                                                }),
                                                (_ctx.selectedCommitment?.VmId)
                                                  ? (_openBlock(), _createBlock(_component_v_btn, {
                                                      key: 0,
                                                      class: "menu-btn",
                                                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openActivityReport()))
                                                    }, {
                                                      default: _withCtx(() => _cache[24] || (_cache[24] = [
                                                        _createTextVNode("Activity Report")
                                                      ])),
                                                      _: 1
                                                    }))
                                                  : _createCommentVNode("", true),
                                                (_ctx.selectedCommitment?.VmId)
                                                  ? (_openBlock(), _createBlock(_component_v_btn, {
                                                      key: 1,
                                                      class: "menu-btn",
                                                      onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.openSecurityReport()))
                                                    }, {
                                                      default: _withCtx(() => _cache[25] || (_cache[25] = [
                                                        _createTextVNode("Security Report")
                                                      ])),
                                                      _: 1
                                                    }))
                                                  : _createCommentVNode("", true)
                                              ])
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "6",
                    class: "p-0"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.dialogLoading)
                        ? (_openBlock(), _createBlock(_component_v_skeleton_loader, {
                            key: 0,
                            class: "mx-auto border",
                            type: "article"
                          }))
                        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createElementVNode("div", _hoisted_8, [
                              _cache[28] || (_cache[28] = _createElementVNode("div", { class: "help-item" }, [
                                _createElementVNode("div", { class: "help-color b-emerald" }),
                                _createElementVNode("span", null, "Active")
                              ], -1)),
                              _cache[29] || (_cache[29] = _createElementVNode("div", { class: "help-item" }, [
                                _createElementVNode("div", { class: "help-color b-orange" }),
                                _createElementVNode("span", null, "Upcoming")
                              ], -1)),
                              _cache[30] || (_cache[30] = _createElementVNode("div", { class: "help-item" }, [
                                _createElementVNode("div", { class: "help-color b-battleship-grey1" }),
                                _createElementVNode("span", null, "Expired")
                              ], -1)),
                              _createElementVNode("div", _hoisted_9, [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => _cache[26] || (_cache[26] = [
                                    _createTextVNode("mdi-star")
                                  ])),
                                  _: 1
                                }),
                                _cache[27] || (_cache[27] = _createElementVNode("span", null, "Action based on", -1))
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_10, [
                              (_ctx.selectedCommitment)
                                ? (_openBlock(), _createBlock(_component_CommitmentMenuTimeline, {
                                    key: 0,
                                    commitment: _ctx.selectedCommitment
                                  }, null, 8, ["commitment"]))
                                : _createCommentVNode("", true)
                            ])
                          ]))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.displayActivityReport && _ctx.selectedCommitment?.VmId)
                ? (_openBlock(), _createBlock(_component_v_dialog, {
                    key: 0,
                    modelValue: _ctx.displayActivityReport,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.displayActivityReport) = $event)),
                    "max-width": "90%",
                    onKeydown: _withKeys(_ctx.closeActivityWatchReport, ["esc"])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_text, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ActivityWatchReport, {
                                vmId: _ctx.selectedCommitment?.VmId,
                                onClose: _ctx.closeActivityWatchReport,
                                isClient: true
                              }, null, 8, ["vmId", "onClose"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onKeydown"]))
                : _createCommentVNode("", true),
              (_ctx.displaySecurityReport && _ctx.selectedCommitment?.VmId)
                ? (_openBlock(), _createBlock(_component_v_dialog, {
                    key: 1,
                    modelValue: _ctx.displaySecurityReport,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.displaySecurityReport) = $event)),
                    "max-width": "900",
                    persistent: "",
                    onKeydown: _withKeys(_ctx.closeSecurityReport, ["esc"])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_text, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_SecurityReport, {
                                vmId: _ctx.selectedCommitment?.VmId,
                                onClose: _ctx.closeSecurityReport,
                                isClient: true
                              }, null, 8, ["vmId", "onClose"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onKeydown"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_dialog, {
                modelValue: _ctx.displayVdSupport,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.displayVdSupport) = $event)),
                "max-width": "900",
                persistent: "",
                onKeydown: _withKeys(_ctx.closeVdSupportPopup, ["esc"])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_VdSupport, {
                    isClient: true,
                    onCancel: _ctx.closeVdSupportPopup,
                    onSendRequest: _ctx.createVdSupport
                  }, null, 8, ["onCancel", "onSendRequest"])
                ]),
                _: 1
              }, 8, ["modelValue", "onKeydown"]),
              (_ctx.showActionModal)
                ? (_openBlock(), _createBlock(_component_CommitmentActionModal, {
                    key: 2,
                    commitmentAction: _ctx.selectedCommitmentAction,
                    commitment: _ctx.selectedCommitment,
                    onOnClose: _ctx.closeCommitmentActionModal,
                    onOnSuccess: _ctx.commitmentActionApplied
                  }, null, 8, ["commitmentAction", "commitment", "onOnClose", "onOnSuccess"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["loading", "disabled"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}