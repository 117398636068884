<template>
  <v-app>
    <loading :isLoading="showLoading" class="h-100">
      <router-view />
    </loading>
    <v-snackbar
      v-for="(snakbar, index) in getSnakbars"
      left
      class="snakbar"
      bottom
      :key="snakbar.id"
      :color="snakbar.status"
      v-model="snakbar.show"
      :timeout="snakbar.timeout"
      :style="`bottom: ${index * 60 + 10}px;`">
      {{ snakbar.body }}
    </v-snackbar>
  </v-app>
</template>
<script lang="ts">
  import { defineComponent } from "vue"
  import Loading from "shared-components/src/components/RootLoading.vue"
  import { mapGetters } from "vuex"
  import store from "@/store"

  export default defineComponent({
    components: {
      Loading,
    },
    name: "App",
    data: () => ({
      isAuthenticated: false,
    }),
    created() {
      store.dispatch("clearSnakbars")
    },
    computed: {
      ...mapGetters(["showLoading", "getSnakbars"]),
    },
  })
</script>
