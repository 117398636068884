import {
  SET_CRITERIA,
  SET_USER_INFO,
  OPEN_QUICK_LOGIN_DIALOG,
  CLOSE_QUICK_LOGIN_DIALOG,
  TOGGLE_DRAWER_OPEN,
  HANDLE_DRAWER_OPEN,
  SHOW_LOADING,
  HIDDEN_LOADING,
  SET_ACTIVITIES,
  SET_SNAKBAR_MODEL,
  REMOVE_SNAKBAR,
  SET_NEW_PROJECT_POPUP,
  LOGIN_EMAIL_SENT,
  REMOVE_ALL_SNAKBARS,
} from "./types"
import { Profile } from "shared-components/src/definitions/config"

export const actions = {
  setNewProjectPopupStatus({ commit }: any, status: boolean) {
    commit(SET_NEW_PROJECT_POPUP, status)
  },
  showErrorMessage({ commit }: any, message: string) {
    const model = {
      body: message,
      status: "error",
      button: "ok",
      timeout: Profile.ErrorSetting.SnackbarTimeout,
      show: true,
      handler: (snakbarId: any) => commit(REMOVE_SNAKBAR, snakbarId),
    }
    commit(SET_SNAKBAR_MODEL, model)
  },
  showSuccessMessage({ commit }: any, message: string) {
    const model = {
      body: message,
      status: "success",
      button: "ok",
      timeout: Profile.ErrorSetting.SnackbarTimeout,
      show: true,
      handler: (snakbarId: any) => commit(REMOVE_SNAKBAR, snakbarId),
    }
    commit(SET_SNAKBAR_MODEL, model)
  },
  showWarningMessage({ commit }: any, message: string) {
    const model = {
      body: message,
      status: "warning",
      button: "ok",
      timeout: Profile.ErrorSetting.SnackbarTimeout,
      show: true,
      handler: (snakbarId: any) => commit(REMOVE_SNAKBAR, snakbarId),
    }
    commit(SET_SNAKBAR_MODEL, model)
  },
  setCriteria({ commit }: any, payload: any) {
    commit(SET_CRITERIA, payload)
  },
  setUserInfo({ commit }: any, payload: any) {
    commit(SET_USER_INFO, payload)
  },
  setActivities({ commit }: any, payload: any) {
    commit(SET_ACTIVITIES, payload)
  },
  openQuickLoginDialog({ commit }: any) {
    commit(OPEN_QUICK_LOGIN_DIALOG)
  },
  closeQuickLoginDialog({ commit }: any) {
    commit(CLOSE_QUICK_LOGIN_DIALOG)
  },
  toggleDrawerOpen({ commit }: any) {
    commit(TOGGLE_DRAWER_OPEN)
  },
  handleDrawerOpen({ commit }: any, value: boolean) {
    commit(HANDLE_DRAWER_OPEN, value)
  },
  setSendLoginStatus({ commit }: any, value: boolean) {
    commit(LOGIN_EMAIL_SENT, value)
  },
  showLoading({ commit }: any) {
    commit(SHOW_LOADING)
  },
  hiddenLoading({ commit }: any) {
    commit(HIDDEN_LOADING)
  },
  clearSnakbars({ commit }: any) {
    commit(REMOVE_ALL_SNAKBARS)
  },
}
